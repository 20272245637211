import React from "react";
import MyRouter from "routers/index";
import { AuthProvider } from "./context/userContext";
import { ToastContainer, toast } from "react-toastify";

function App() {
  return (
    <>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      <AuthProvider>
        <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200 min-h-screen">
          <div className="flex flex-col min-h-screen">
          <MyRouter/>

          </div>
        </div>
      </AuthProvider>
    </>
  );
}

export default App;
