import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { FC } from "react";
import CommonLayout from "./CommonLayout";

export interface PageAddListing3Props {
  formik: any;
}

const PageAddListing3: FC<PageAddListing3Props> = ({ formik }) => {
  const handleChangeData = (value: number, type: string) => {
    formik.setFieldValue(type, value);
  };
  return (
    <CommonLayout
      index="03"
      backtHref="/add-listing-2"
      nextHref="/add-listing-4"
    >
      <>
        <h2 className="text-2xl font-semibold">Rooms and Beds</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
        
          <NcInputNumber
            label="Bedrooms"
            defaultValue={formik.values.bedrooms}
            onChange={(value) => handleChangeData(value, "bedrooms")}
          />
          {formik.errors && formik.errors.bedrooms && (
            <span className="text-red-500 text-sm">
              {formik.errors.bedrooms}
            </span>
          )}
          <NcInputNumber
            label="Beds"
            defaultValue={formik.values.beds}
            onChange={(value) => handleChangeData(value, "beds")}
          />
          {formik.errors && formik.errors.beds && (
            <span className="text-red-500 text-sm">{formik.errors.beds}</span>
          )}
          <NcInputNumber
            label="Bathrooms"
            defaultValue={formik.values.bathrooms}
            onChange={(value) => handleChangeData(value, "bathrooms")}
          />
          {formik.errors && formik.errors.bathrooms && (
            <span className="text-red-500 text-sm">
              {formik.errors.bathrooms}
            </span>
          )}
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing3;
