import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useFormik } from "formik";
import * as Yup from "yup";
import { API_URL } from "../../api/config";
import axios from "axios";
import { toast } from "react-toastify";
import { useContext } from "react";
import { AuthContext } from "../../context/userContext";
import { useNavigate, useLocation } from "react-router-dom";

// Import your custom components

import PageAddListing1 from "./PageAddListing1";
import PageAddListing2 from "./PageAddListing2";
import PageAddListing3 from "./PageAddListing3";
import PageAddListing4 from "./PageAddListing4";
import PageAddListing5 from "./PageAddListing5";
import PageAddListing6 from "./PageAddListing6";
import PageAddListing7 from "./PageAddListing7";
import PageAddListing8 from "./PageAddListing8";
import PageAddListing9 from "./PageAddListing9";

const HorizontalLinearStepper: React.FC = () => {
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = React.useState(0);
  const [isLoading, setisLoading] = useState(false);
  const authContext = useContext(AuthContext);
  const [isOtpSent, setIsOtpSent] = useState(false);

  const { getOwnerProperty } = authContext;

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const pageValidation1 = Yup.object({
    title: Yup.string().required("Place name is required"),

    propertyPhoneNumber: Yup.string()
      .matches(/^\d+$/, "Phone number can only contain numbers")
      .min(10, "Phone number must be at least 10 digits")
      .max(15, "Phone number must be no more than 15 digits")
      .required("Property phone number is required"),
    otp: Yup.number()
      .typeError("OTP must be a number")
      .required("OTP is required"),
  });
  const pageValidation2 = Yup.object({
    category: Yup.string().required("Category is required"),
    landmark: Yup.string().required("Landmark is required"),
    type: Yup.string().required("Type of place is required"),
    Address: Yup.string().required("Address is required"),
    nearest_airport: Yup.string(),
  });
  const pageValidation3 = Yup.object({
    bedrooms: Yup.number().required("Number of bedrooms is required"),
    beds: Yup.number().required("Number of beds is required"),
    bathrooms: Yup.number().required("Number of bathrooms is required"),
  });
  // Page 4
  const pageValidation4 = Yup.object({
    // amenities: Yup.string().required("Amenities information is required"),
  });
  // Page 5
  const pageValidation5 = Yup.object({
    cover_image: Yup.string().required("Cover image is required"),
    galleryImgs: Yup.array()
      .of(Yup.string())
      .required("Gallery images are required"),
  });
  // Page 6
  const pageValidation6 = Yup.object({
    place_descriptions: Yup.string().required("Place description is required"),
  });
  // Page 7
  const pageValidation7 = Yup.object({
    propertyRules: Yup.string().required("Property rules is required"),
  });
  // Page 8
  const pageValidation8 = Yup.object({
    price: Yup.number()
      .typeError("Price must be number")
      .min(0, "Price must be greater than zero")
      .required("Price is required"),
    coins_required: Yup.number()
      .min(0, "Coins must be greater than zero")
      .required("Coins is required"),
  });

  const pageValidation9 = Yup.object({});

  const token = localStorage.getItem("token");

  // description phone number check!
  const checkWordArray = async (newStr: any) => {
    const numbers = [
      "zero",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
      "ten",
    ];

    numbers.map((number, index) => {
      newStr = newStr.replace(new RegExp(number, "ig"), `${index}`);
    });
    let digits = newStr.match(/\d/g);
    let result = [];
    if (digits) {
      let str = "";
      for (let i = 0; i < digits.length; i++) {
        let diff =
          i > 0
            ? Math.abs(
                newStr.indexOf(digits[i]) - newStr.indexOf(digits[i - 1])
              )
            : 0;
        newStr = newStr.substring(newStr.indexOf(digits[i - 1]));
        if (diff < 10) {
          str += digits[i];
        } else {
          result.push(str);
          str = digits[i];
        }
        if (i + 1 === digits.length) {
          result.push(str);
        }
      }
    }
    let numberPresent = false;
    result.length > 0 &&
      result.map((item) => {
        const isValidIndianpropertyPhoneNumber =
          /^(?:\+?91|0)?[7-9]\d{9}$/.test(item);
        if (isValidIndianpropertyPhoneNumber) {
          numberPresent = true;
          formik.setFieldError(
            "place_descriptions",
            "Please don't include phone numbers in the description, Kindly review your description and try again."
          );
        }
      });
    return numberPresent;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: "",
      subtype: "",
      title: "",
      propertyPhoneNumber: "",
      // country: "India",
      category: "",
      area_name: "",
      floor: "",
      city: "",
      landmark: "",
      Address: "",
      latitude: 19.05700553097587,
      longitude: 73.00370181755643,
      nearest_airport: "",
      bedrooms: 0,
      beds: 0,
      bathrooms: 0,
      kitchen: 0,
      amenities: [],
      // couple_friendly: false,
      // id_required: false,
      // pet: false,
      // party_organizing: false,
      // cooking: false,
      // smoking: false,
      // drinking: false,
      propertyHighlights: [],
      typeOfLocation: [],
      additional_rules: [],
      place_descriptions: "",
      price: "",
      coins_required: "",
      cover_image: "",
      galleryImgs: [],
      airbnb_link: "",
      propertyRules: "",
    },

    validationSchema: () => {
      const stepValidationSchemas = [
        pageValidation1,
        pageValidation2,
        pageValidation3,
        pageValidation4,
        pageValidation5,
        pageValidation6,
        pageValidation7,
        pageValidation8,
        pageValidation9,
      ];

      return stepValidationSchemas[activeStep];
    },
    onSubmit: async (values) => {
      if (activeStep === 5) {
        if (formik.values.place_descriptions) {
          const isPresent = await checkWordArray(
            formik.values.place_descriptions
          );
          if (isPresent) {
            console.error("Phone number found in description.");
            return;
          } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
        } else {
          formik.setFieldError(
            "place_descriptions",
            "place description should not be empty."
          );
          return;
        }
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        return;
      }
    },
  });

  const handleFormSubmission = async () => {
    setisLoading(true);
    try {
      await formik.validateForm();

      const formData = {
        ...formik.values,
        cover_image: formik.values.cover_image, // base64 cover image
        galleryImgs: formik.values.galleryImgs, // array of base64 gallery images
      };

      // if(!localStorage.getItem("token")){
      //   toast.error("Please login before adding property")
      // }else{
      await handleUplaod(formData);

      // }
    } catch (error) {
      console.error("Error during form submission:", error);
      toast.error("Failed to submit form. Please try again.");
    }
    setisLoading(false);
  };

  const handleUplaod = async (formData: any) => {
    try {
      const response = await axios.post(
        `${API_URL}/property/add-property`,
        formData,
        {
          headers: { token },
        }
      );

      const text = response.data.message || response.data.result.msg;

      if (!response.data.error) {
        toast.success(text);
        getOwnerProperty();
        setTimeout(() => {
          navigate("/property");
        }, 1000);
      } else {
        toast.error(text);
      }
    } catch (err) {
      console.error("Upload error:", err);
      toast.error("Something went wrong. Please try again.");
    }
  };

  const components = [
    <PageAddListing1
      formik={formik}
      setActiveStep={setActiveStep}
      setIsOtpSent={setIsOtpSent}
      isOtpSent={isOtpSent}
    />,
    <PageAddListing2 formik={formik} />,
    <PageAddListing3 formik={formik} />,
    <PageAddListing4 formik={formik} />,
    <PageAddListing5 formik={formik} />,
    <PageAddListing6 formik={formik} />,
    <PageAddListing7 formik={formik} />,
    <PageAddListing8 formik={formik} />,
    <PageAddListing9 formik={formik} />,
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <React.Fragment>
        {/* {React.cloneElement(components[activeStep], { formik })} */}
        {components[activeStep]}

        <div className="px-4 max-w-3xl mx-auto pb-12 pt-1 sm:py-12 lg:pb-12">
          <div className="flex justify-end space-x-5">
            <ButtonSecondary
              onClick={() => {
                handleBack();
                // Scroll to the top of the page
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              // disabled={components[activeStep] === components[0]}
              disabled={activeStep === 0}
            >
              Go back
            </ButtonSecondary>{" "}
            {activeStep === components.length - 1 ? (
              <ButtonPrimary
                type="button"
                disabled={isLoading}
                onClick={async () => {
                  await handleFormSubmission();
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                Submit
              </ButtonPrimary>
            ) : (
              <ButtonPrimary
                type="button"
                onClick={() => {
                  formik.handleSubmit();
                  // Scroll to the top of the page
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                Continue
              </ButtonPrimary>
            )}
          </div>
        </div>
      </React.Fragment>
    </Box>
  );
};

export default HorizontalLinearStepper;
