import { FC, useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../api/config";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSearchParams } from "react-router-dom";
import { AuthContext } from "context/userContext";
import email from "../../images/email.svg";
import firstname from "../../images/firstname.svg";
import lastname from "../../images/lastname.svg";

export interface PageSignUpProps {
  className?: string;
}

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const authContext = useContext(AuthContext);
  const { getAdminData } = authContext;
  const [isLoading, setisLoading] = useState(false);
  let [searchParams] = useSearchParams();

  useEffect(() => {
    var phone = searchParams?.get("phoneNumber");
    if (phone) {
      setPhoneNumber(phone);
    }
  }, []);

  const handleSignup = async (values: any) => {
    setisLoading(true);
    try {
      const response = await axios.post(`${API_URL}/owner/owner-signup`, {
        ...values,
        phoneNumber,
      });

      const text = response.data.message;

      if (response.data.error === false) {
        toast.success(text);
        localStorage.setItem("token", response.data.token);
        setTimeout(() => {
          navigate("/property");
        }, 1000);
        getAdminData();
      }
      if (response.data.error === true && !!response.data.result) {
        toast.error(response.data.result.msg);
      }

      if (response.data.error === true) {
        toast.error(text);
        navigate("/login");
      }
    } catch (error) {
      toast.error("Error during Signup");
      console.error("Error during Signup:", error);
    }
    setisLoading(false);
  };
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      checkbox: false,
    },
    validationSchema: Yup.object({
      firstname: Yup.string()
        .min(2, "Firstname should be of atleast 2 words")
        .matches(/^[A-Za-z]+$/, "Only alphabets are allowed")
        .required("Firstname should not be empty!"),
      lastname: Yup.string()
        .matches(/^[A-Za-z]+$/, "Only alphabets are allowed")
        .min(2, "Lastname should be of atleast 2 words")
        .required("Lastname should not be empty!"),
      email: Yup.string().email().required("Email should not be empty!"),
      checkbox: Yup.boolean()
        .oneOf([true], "You must agree to the terms first.")
        .required("You must agree to the terms first."),
    }),
    onSubmit: handleSignup,
  });

  return (
    <div
      className={`nc-PageSignUp flex flex-grow ${className} w-full`}
      data-nc-id="PageSignUp"
    >
      <Helmet>
        <title>Sign up || Booking React Template</title>
      </Helmet>
      <div className="container my-20 lg:mb-8 relative ">
        <h2 className=" flex items-center text-3xl leading-[115%] md:text-4xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Welcome abroad!
        </h2>
        <p className="flex items-center justify-center mb-8 text-neutral-500">
          Please enter your details to get started.
        </p>

        <div className="max-w-md mx-auto space-y-6 ">
          <form
            className="grid grid-cols-1 gap-6"
            onSubmit={validation.handleSubmit}
            method="post"
          >
            <div className="relative mt-1">
              <img
                src={firstname}
                alt="firstname"
                className="absolute top-4 left-3"
              />
              <Input
                name="firstname"
                type="text"
                className={`${
                  validation.touched.firstname && validation.errors.firstname
                    ? "border border-red-500 mt-1 text-neutral-600 pl-9"
                    : "mt-1 text-neutral-600 pl-9"
                }`}
                value={validation.values.firstname}
                onChange={validation.handleChange}
                placeholder="Enter first name"
              />
              {validation.touched.firstname && validation.errors.firstname ? (
                <span className="text-red-500 text-sm flex justify-center items-center my-1	">
                  {validation.errors.firstname}
                </span>
              ) : null}
            </div>

            <div className="relative mt-1">
              <img
                src={lastname}
                alt="lastname"
                className="absolute top-4 left-3"
              />
              <Input
                name="lastname"
                type="text"
                className={`${
                  validation.touched.lastname && validation.errors.lastname
                    ? "border border-red-500 mt-1 text-neutral-600 pl-9"
                    : "mt-1 text-neutral-600 pl-9"
                }`}
                value={validation.values.lastname}
                onChange={validation.handleChange}
                placeholder="Enter last name"
              />
              {validation.touched.lastname && validation.errors.lastname ? (
                <span className="text-red-500 text-sm flex justify-center items-center my-1	">
                  {validation.errors.lastname}
                </span>
              ) : null}
            </div>

            <div className="relative ">
              <img src={email} alt="email" className="absolute top-4 left-3" />
              <Input
                name="email"
                type="email"
                className={`${
                  validation.touched.email && validation.errors.email
                    ? "border border-red-500 mt-1 text-neutral-600 pl-9"
                    : "mt-1 text-neutral-600 pl-9"
                }`}
                value={validation.values.email}
                onChange={validation.handleChange}
                placeholder="Enter your email"
              />
              {validation.touched.email && validation.errors.email ? (
                <span className="text-red-500 text-sm flex justify-center items-center my-1	">
                  {validation.errors.email}
                </span>
              ) : null}
            </div>

            <div>
              <input
                type="checkbox"
                id="checkbox"
                checked={validation.values.checkbox}
                onChange={validation.handleChange}
              />
              <label htmlFor="checkbox" className="pl-3 text-sm">
                I agree to Terms & conditions of DirecStays{" "}
              </label>
              {validation.touched.checkbox && validation.errors.checkbox ? (
                <span className="text-red-500 text-sm text-left	flex justify-center items-center my-1">
                  {validation.errors.checkbox}
                </span>
              ) : null}
            </div>

            <ButtonPrimary
              disabled={isLoading}
              type="submit"
              className="blue-btn"
            >
              Create Account
            </ButtonPrimary>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
